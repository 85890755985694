import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NullableString } from '../types';
export interface BalanceDetails {
	id: NullableString;
	parts: NullableString;
	investor: NullableString;
	investorName?: NullableString;
}
const initialState = {
	value: {
		id: null,
		parts: null,
		investor: null,
		investorName: null,
	} as BalanceDetails,
};

export const balanceDataSlice = createSlice({
	name: 'balanceData',
	initialState,
	reducers: {
		loadBalanceDetails: (state, action: PayloadAction<BalanceDetails>) => {
			state.value = action.payload;
		},
		getSelectedItem: (state, action: PayloadAction<BalanceDetails>) => {
			state.value = action.payload;
		},
	},
});

export const { loadBalanceDetails, getSelectedItem } = balanceDataSlice.actions;

export default balanceDataSlice.reducer;
