import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Fab, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showAlertBox } from '../../../store/alertBoxSlice';
import { DEFAULT_ALERT_ERROR, DEFAULT_ALERT_SUCCESS } from '../../../constants';
import { RootState } from '../../../store';
import { mapErrorToApiMessage } from '../../../utils';
import ConfirmDialog from '../../ConfirmDialog';
import { displayConfirm } from '../../../store/confirmDialogSlice';
import { updatePageTitle } from '../../../store/appDataSlice';
import { PosController } from './PosController';
import { PosService } from '../../../services/Pos';
import { LayoutBoard } from '../../layouts/LayoutBoard';
import { PosItem } from '../../../types/PosItem';
import { PosCard } from '../../common/PosCard';

export function MyPos() {
	/**
	 * Subscriptions
	 */
	const dispatch = useDispatch();
	const navigate = useNavigate();

	dispatch(updatePageTitle('Points de ventes'));

	const selectedAdRow = useSelector((state: RootState) => state.posData.value);

	const { open: isOpenConfirm } = useSelector((state: RootState) => state.confirmDialog.value);

	/**
	 * States
	 */
	const [isLoading, setIsLoading] = useState(false);
	const [rows, setRows] = useState([]);

	/**
	 * functionnals methodes
	 */

	const remove = async (id: string) => {
		try {
			const resp = await PosController.delete(id);
			if (resp?.status === 200) {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_SUCCESS,
						message: 'point de ventes supprimé',
						open: true,
					})
				);
			}
		} catch (error: any) {
			console.error(error);
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: mapErrorToApiMessage(error.response.data?.message) || 'Suppression echouée',
					open: true,
				})
			);
		}
	};

	const onDialogClose = (e: any) => {
		dispatch(displayConfirm({ open: false }));
	};

	const onDialogOk = async (e: any) => {
		if (selectedAdRow.id) await remove(selectedAdRow.id);
		loadData();
		dispatch(displayConfirm({ open: false }));
	};

	const loadData = async () => {
		setIsLoading(true);
		const result = await PosService.getAll();
		if (result?.status === 200) {
			console.log(result.data);
			setRows(result.data.map((d: any) => ({ ...d, id: d._id })));
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: " Impossible d'afficher la liste",
					open: true,
				})
			);
		}
		setIsLoading(false);
	};
	/**
	 * Hooks
	 */
	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// create Action component

	return (
		<LayoutBoard>
			<Typography variant='h3' sx={{ mt: 2, ml: 2 }}>
				Points de ventes
			</Typography>
			<ConfirmDialog
				open={isOpenConfirm}
				title='Suppression point de ventes'
				content='Voulez-vous vraiment supprimer cette point de ventes ?'
				okBtnLabel='Oui'
				closeBtnLabel='Non'
				onClose={onDialogClose}
				onOk={onDialogOk}
			/>

			{isLoading && (
				<Box sx={{ display: 'flex', justifyContent: 'center' }}>
					<CircularProgress />
				</Box>
			)}

			<Grid container spacing={2} sx={{ p: 2 }}>
				{rows.map((pos: PosItem) => (
					<Grid item xs={12} md={6} key={pos.id}>
						<PosCard pos={pos} />
					</Grid>
				))}
			</Grid>

			<Fab
				onClick={() => navigate('/pos/new')}
				style={{
					position: 'absolute',
					bottom: 35,
					right: 35,
				}}
				color='primary'
				aria-label='add'
			>
				<AddIcon />
			</Fab>
		</LayoutBoard>
	);
}
