import React from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Menu,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material';
import { formatDate } from '../../utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadPosDetails } from '../../store/posDataSlice';
import { showAlertBox } from '../../store/alertBoxSlice';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_FEMALE_CREATION_ERROR,
	LABEL_FEMALE_CREATION_SUCCESS,
} from '../../constants';
import { PosService } from '../../services/Pos';
import { PosItem } from '../../types/PosItem';
import { displayConfirm } from '../../store/confirmDialogSlice';

export const PosCard = ({ pos, ...othersProps }: any) => {
	const { id, name, description, startDate, createdAt } = pos;

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleItemEditClick = (e: any, id: string) => {
		e.preventDefault();
		navigate(`/pos/${id}`);
	};

	const handleItemRecettesClick = (e: any, row: any) => {
		e.preventDefault();
		dispatch(loadPosDetails({ ...row, id: row._id }));
		navigate(`/pos/${row.id}/balances`);
	};

	const handleItemRemoveClick = (e: any, row: any) => {
		dispatch(loadPosDetails(row));
		dispatch(displayConfirm({ open: true }));
	};

	const handleItemDuplicationClick = async (e: any, row: any) => {
		e.preventDefault();
		const item = { name: `Copie ${row.name}`, description, startDate };
		const resp = await PosService.create(item as PosItem);
		if (resp?.status === 201) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: 'point de vente ' + LABEL_FEMALE_CREATION_SUCCESS,
					open: true,
				})
			);
			navigate(0);
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: 'point de vente ' + LABEL_FEMALE_CREATION_ERROR,
					open: true,
				})
			);
		}
	};

	return (
		<Card {...othersProps}>
			<CardContent>
				<Typography variant='h5' sx={{ textAlign: 'left', p: 1 }} color='text.primary'>
					Pos : {name}
				</Typography>
				<Typography sx={{ fontSize: 14, p: 1 }} color='text.secondary'>
					{description}
				</Typography>

				<TableContainer>
					<Table>
						<TableBody>
							<TableRow>
								<TableCell>Démarrage </TableCell>
								<TableCell align='right'>{formatDate(startDate)}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Création</TableCell>
								<TableCell align='right'>{formatDate(createdAt)}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</CardContent>
			<Box sx={{ m: 2 }}>
				<Button variant='outlined' onClick={(e) => handleItemRecettesClick(e, pos)}>
					Recettes
				</Button>

				<Button
					id={`more-button-${id}`}
					aria-controls={open ? `more-menu-${id}` : undefined}
					aria-haspopup='true'
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
				>
					Plus
				</Button>
				<Menu
					id={`more-menu-${id}`}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': `more-button-${id}`,
					}}
				>
					<MenuItem onClick={(e) => handleItemRemoveClick(e, pos)}>Supprimer</MenuItem>
					<MenuItem onClick={(e) => handleItemDuplicationClick(e, pos)}>Dupliquer</MenuItem>
					<MenuItem onClick={(e) => handleItemEditClick(e, id)}>Modifier</MenuItem>
				</Menu>
			</Box>
		</Card>
	);
};
