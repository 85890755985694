import axios from 'axios';
import { APP_API_URL } from '../constants';
import { Auth } from './Auth';
import { BalanceItem } from '../types/BalanceItem';
import { dateToServerFormat } from '../utils';

export class BalanceService {
	static async create(pos: string, data: BalanceItem) {
		data.saleDate = dateToServerFormat(data.saleDate as string);
		return await axios({
			method: 'POST',
			url: APP_API_URL + 'pos/' + pos + '/balances',
			data,
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: `Bearer ${Auth.getToken()}`,
				'Content-Type': 'application/json',
			},
		});
	}

	static async update(pos: string, data: BalanceItem) {
		let result = null;
		const id = data.id;
		delete data.id;
		try {
			const response = await axios({
				method: 'PUT',
				url: APP_API_URL + 'pos/' + pos + '/balances/' + id,
				data,
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${Auth.getToken()}`,
					'Content-Type': 'application/json',
				},
			});
			result = response;
		} catch (error) {
			console.error(error);
		}

		return result;
	}

	static async getAll() {
		let result = null;
		try {
			const response = await axios({
				method: 'GET',
				url: APP_API_URL + 'balances',
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${Auth.getToken()}`,
				},
			});
			result = response;
		} catch (error) {
			console.error(error);
		}

		return result;
	}

	static async getByPos(pos: string) {
		let result = null;
		try {
			const response = await axios({
				method: 'GET',
				url: APP_API_URL + 'pos/' + pos + '/balances',
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${Auth.getToken()}`,
				},
			});
			result = response;
		} catch (error) {
			console.error(error);
		}

		return result;
	}
	static async getMine() {
		let result = null;
		try {
			const response = await axios({
				method: 'GET',
				url: APP_API_URL + 'my/balances',
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${Auth.getToken()}`,
				},
			});
			result = response;
		} catch (error) {
			console.error(error);
		}

		return result;
	}
	static async get(pos: string, id: any) {
		let result;
		try {
			result = await axios({
				method: 'GET',
				url: APP_API_URL + 'pos/' + pos + '/balances/' + id,
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${Auth.getToken()}`,
				},
			});
		} catch (error) {
			console.error(error);
		}

		return result;
	}

	static async delete(pos: string, id: string) {
		return await axios({
			method: 'DELETE',
			url: APP_API_URL + 'pos/' + pos + '/balances/' + id,
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: `Bearer ${Auth.getToken()}`,
			},
		});
	}
}
