import { BalanceService } from '../../../../services/Balance';
import { BalanceItem } from '../../../../types/BalanceItem';

export class BalanceController {
	static createDto(adDto: BalanceItem) {
		return adDto;
	}
	static updateDto(adDto: BalanceItem) {
		return adDto;
	}

	static async create(pos: string, data: BalanceItem) {
		return await BalanceService.create(pos, data);
	}

	static async update(pos: string, data: BalanceItem) {
		return await BalanceService.update(pos, data);
	}

	static async delete(pos: string, id: string) {
		return await BalanceService.delete(pos, id);
	}
}
