import React, { useEffect, useState } from 'react';
import { Box, Card, Fab, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { showAlertBox } from '../../../../store/alertBoxSlice';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	PAGE_SIZE,
	PAGE_SIZE_OPTIONS,
} from '../../../../constants';
import { RootState } from '../../../../store';
import { loadPosDetails } from '../../../../store/posDataSlice';
import { mapErrorToApiMessage } from '../../../../utils';
import ConfirmDialog from '../../../ConfirmDialog';
import { displayConfirm } from '../../../../store/confirmDialogSlice';
import { updatePageTitle } from '../../../../store/appDataSlice';
import { BalanceService } from '../../../../services/Balance';
import { LayoutBoard } from '../../../layouts/LayoutBoard';
import { BalancesColumns } from './BalancesColumns';
import { PosService } from '../../../../services/Pos';
import { loadBalanceDetails } from '../../../../store/balanceDataSlice';
import { BalanceController } from './BalanceController';

export function Balances() {
	/**
	 * Subscriptions
	 */
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const params = useParams();

	dispatch(updatePageTitle('Recettes '));

	const selectedPos = useSelector((state: RootState) => state.posData.value);
	const selectedBalance = useSelector((state: RootState) => state.balanceData.value);

	const { open: isOpenConfirm } = useSelector((state: RootState) => state.confirmDialog.value);

	/**
	 * States
	 */
	const [isLoading, setIsLoading] = useState(false);
	const [rows, setRows] = useState([]);
	/**
	 * functionnals methodes
	 */

	const remove = async (id: string) => {
		try {
			const resp = await BalanceController.delete(params.pos as string, id);
			if (resp?.status === 200) {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_SUCCESS,
						message: 'recette supprimée',
						open: true,
					})
				);
			}
		} catch (error: any) {
			console.error(error);
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: mapErrorToApiMessage(error.response.data?.message) || 'Suppression echouée',
					open: true,
				})
			);
		}
	};

	const handleItemRemoveClick = (e: any, row: any) => {
		dispatch(loadBalanceDetails(row));
		dispatch(displayConfirm({ open: true }));
	};
	const onDialogClose = (e: any) => {
		dispatch(displayConfirm({ open: false }));
	};

	const onDialogOk = async (e: any) => {
		if (selectedBalance.id) await remove(selectedBalance.id);
		loadData();
		dispatch(displayConfirm({ open: false }));
	};

	const handleItemEditClick = (e: any, id: string) => {
		e.preventDefault();
		navigate(`/pos/${params.pos}/balances/${id}`);
	};

	const loadPosData = () => {
		PosService.get(params.pos)
			.then((resp: any) => {
				dispatch(loadPosDetails(resp.data));
			})
			.catch((error) => {
				console.error(error);
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_ERROR,
						message: 'Erreur  lors du chargement des details du point de ventes',
						open: true,
					})
				);
			});
	};

	const loadData = async () => {
		setIsLoading(true);
		loadPosData();
		const result = await BalanceService.getByPos(params.pos as string);
		if (result?.status === 200) {
			console.log(result.data);
			setRows(result.data.map((d: any) => ({ ...d, id: d._id })));
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: " Impossible d'afficher la liste",
					open: true,
				})
			);
		}
		setIsLoading(false);
	};

	/**
	 * Hooks
	 */
	useEffect(() => {
		loadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<LayoutBoard>
			<ConfirmDialog
				open={isOpenConfirm}
				title='Suppression recette'
				content='Voulez-vous vraiment supprimer cette recette ?'
				okBtnLabel='Oui'
				closeBtnLabel='Non'
				onClose={onDialogClose}
				onOk={onDialogOk}
			/>
			<Box sx={{ m: 2 }}>
				<Card variant='outlined' sx={{ pt: 2, pl: 2, pb: 2 }}>
					<div style={{ display: 'flex' }}>
						<Typography variant='h6'>{selectedPos.name}</Typography>
					</div>
					<div style={{ display: 'flex', gap: '20px' }}>
						<div>Description : {selectedPos.description}</div>
						<div>Date : {selectedPos.startDate}</div>
					</div>
				</Card>
			</Box>
			<Box
				sx={{
					width: '98%',
					backgroundColor: '#fff',
					m: 2,
				}}
			>
				<DataGrid
					autoHeight
					loading={isLoading}
					rows={rows}
					columns={BalancesColumns({
						handleItemEditClick,
						handleItemRemoveClick,
					})}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize: PAGE_SIZE,
							},
						},
					}}
					pageSizeOptions={PAGE_SIZE_OPTIONS}
					checkboxSelection
					disableRowSelectionOnClick
				/>
			</Box>

			<Fab
				onClick={() => navigate(`/pos/${params.pos}/balances/new`)}
				sx={{ position: 'absolute', bottom: 35, right: 35 }}
				color='primary'
				aria-label='add'
			>
				<AddIcon />
			</Fab>
		</LayoutBoard>
	);
}
