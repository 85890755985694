import { NotifyBox } from '../types/NotifyBox';

export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
export const APP_API_URL = process.env.REACT_APP_API_URL;
export const APP_LOCAL_STORAGE_KEY = process.env.REACT_APP_COOKIES;

export const DEFAULT_ALERT_SUCCESS = {
	severity: 'success',
	open: false,
	message: '',
	autoHideDuration: 6000,
} as NotifyBox;
export const DEFAULT_ALERT_ERROR = {
	severity: 'error',
	open: true,
	message: '',
	autoHideDuration: 6000,
} as NotifyBox;
export const DEFAULT_ALERT_WARNING = {
	severity: 'warning',
	open: true,
	message: '',
	autoHideDuration: 6000,
} as NotifyBox;

export const LABEL_LOGIN_ERROR = 'Identifiant ou mot de passe incorrect';
export const LABEL_LOGIN_SUCCESS = `Bienvenue dans ${APP_NAME}`;
export const LABEL_REGISTRATION_SUCCESS = `Inscription "${APP_NAME}" réussie vous avez reçu un e-mail`;
export const LABEL_REGISTRATION_ERROR = 'Inscription échouée';
export const LABEL_REGISTRATION_CGU_ERROR = 'Vous devez accepter les conditions';
export const LABEL_REGISTRATION_CGU =
	"Je souhaite recevoir de l'inspiration, des promotions marketing et des mises à jour par e-mail.";
export const LABEL_RESET_PASSWORD_CGU = "J'acceptes les CGU.";

export const LABEL_AD_CREATION_SUCCESS = 'Annonce crée avec succès';
export const LABEL_AD_CREATION_ERROR = 'Erreur création annonce';

export const LABEL_FEMALE_CREATION_SUCCESS = 'créee avec succès';
export const LABEL_FEMALE_CREATION_ERROR = 'Erreur création ###';

export const LABEL_FEMALE_EDIT_SUCCESS = 'modifiée avec succès';
export const LABEL_EDIT_SUCCESS = 'modifié avec succès';
export const LABEL_EDIT_ERROR = 'Erreur modification ';

export const LABEL_LOAD_SUCCESS = 'chargée avec succès';
export const LABEL_LOAD_ERROR = 'Erreur chargement ###';
export const LABEL_AD = 'annonce';
export const WORD_TAG = '###';
export const LABEL_ACCOUNT_ACTIVATION_SUCCESS =
	'Félicitations ! Nous sommes ravis de vous informer que votre compte a été activé avec succès';
export const LABEL_ACCOUNT_ACTIVATION_ERROR = 'Echec activation de compte, lien expiré ou invalide';
/**
 * Console Messages
 */
export const CONSOLE_LOGOUT_MESSAGE = `-- logged out properly from ${APP_NAME}`;
/**
 * Default Ads form values
 */
export const API_ERROR_OWNER_ONLY = 'Forbidden';
export const APP_ERROR_OWNER_ONLY = 'Accès refusé';

/**
 * darkMode
 */
export const THEME_DARK = 'dark';
export const THEME_LIGHT = 'light';

export const TOGGLE_THEME_DARK = 'passer au dark mode';
export const TOGGLE_THEME_LIGHT = 'passer au light mode';
export const ADMIN = 1;
export const SELLER = 2;
export const ROLES = [
	{ id: 1, name: 'Administrateur' },
	{ id: 2, name: 'Entretpreneur' },
];
export const DATE_FROM_API_FORMAT = 'MM-DD-YYYY';
export const DATE_TO_API_FORMAT = 'MM-DD-YYYY';
export const PAGE_SIZE_OPTIONS = [10];
export const PAGE_SIZE = 10;
