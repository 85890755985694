import axios from 'axios';
import { APP_API_URL } from '../constants';
import { Auth } from './Auth';

export class CountersService {
	static async count(num: number) {
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'counters/' + num,
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: `Bearer ${Auth.getToken()}`,
			},
		});
	}
}
