import axios from 'axios';
import { APP_API_URL } from '../constants';
import { Auth } from './Auth';
import { PosItem } from '../types/PosItem';
import { dateToServerFormat } from '../utils';

export class PosService {
	static async create(data: PosItem) {
		let result = null;
		data.startDate = dateToServerFormat(data.startDate as string);
		try {
			const response = await axios({
				method: 'POST',
				url: APP_API_URL + 'pos',
				data,
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${Auth.getToken()}`,
					'Content-Type': 'application/json',
				},
			});
			result = response;
		} catch (error) {
			console.error(error);
		}

		return result;
	}

	static async update(data: PosItem) {
		let result = null;
		data.startDate = dateToServerFormat(data.startDate as string);
		const id = data.id;
		delete data.id;
		try {
			const response = await axios({
				method: 'PUT',
				url: APP_API_URL + 'pos/' + id,
				data,
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${Auth.getToken()}`,
					'Content-Type': 'application/json',
				},
			});
			result = response;
		} catch (error) {
			console.error(error);
		}

		return result;
	}

	static async getAll() {
		let result = null;
		try {
			const response = await axios({
				method: 'GET',
				url: APP_API_URL + 'pos',
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${Auth.getToken()}`,
				},
			});
			result = response;
		} catch (error) {
			console.error(error);
		}

		return result;
	}
	static async get(id: any) {
		let result;
		try {
			result = await axios({
				method: 'GET',
				url: APP_API_URL + 'pos/' + id,
				headers: {
					'Access-Control-Allow-Origin': '*',
					Authorization: `Bearer ${Auth.getToken()}`,
				},
			});
		} catch (error) {
			console.error(error);
		}

		return result;
	}

	static async delete(id: string) {
		return await axios({
			method: 'DELETE',
			url: APP_API_URL + 'pos/' + id,
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: `Bearer ${Auth.getToken()}`,
			},
		});
	}
}
