import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from '../../services/Auth';
import { Box, CssBaseline, IconButton, Menu, MenuItem, styled } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AccountCircle } from '@mui/icons-material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import Sidebar from '../Sidebar';
import { Copyright } from '../Copyright';
import { AlertBox } from '../AlertBox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { showAlertBox } from '../../store/alertBoxSlice';
import {
	DEFAULT_ALERT_SUCCESS,
	THEME_DARK,
	THEME_LIGHT,
	TOGGLE_THEME_DARK,
	TOGGLE_THEME_LIGHT,
} from '../../constants';
import { updateTheme, userLogout } from '../../store/appDataSlice';

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
	open?: boolean;
}

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

export function LayoutBoard({ children, container }: any) {
	const pageTitle = useSelector((state: RootState) => state.appData.title);
	const theme = useSelector((state: RootState) => state.appData.theme);
	const userDetails = Auth.getUser();
	const dispatch = useDispatch();

	const [open, setOpen] = React.useState(true);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const navigate = useNavigate();

	const logout = (e: any) => {
		e.preventDefault();
		dispatch(userLogout());
		navigate('/login');
	};

	const handleMenu = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const toggleDrawer = () => {
		setOpen(!open);
	};

	const toggleTheme = (e: any) => {
		e.preventDefault();
		if (theme === THEME_DARK) dispatch(updateTheme(THEME_LIGHT));
		else dispatch(updateTheme(THEME_DARK));
	};

	const dialog = useSelector((state: RootState) => state.alertBox.value);
	const { message, open: isOpen, severity } = dialog;

	return (
		<Box sx={{ display: 'flex' }}>
			<AlertBox
				handleClose={() => dispatch(showAlertBox({ ...DEFAULT_ALERT_SUCCESS, open: false }))}
				open={isOpen}
				message={message}
				severity={severity}
			/>
			<CssBaseline />
			<AppBar position='absolute' open={open}>
				<Toolbar
					sx={{
						pr: '24px', // keep right padding when drawer closed
					}}
				>
					<IconButton
						edge='start'
						color='inherit'
						aria-label='open drawer'
						onClick={toggleDrawer}
						sx={{
							marginRight: '36px',
							...(open && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Typography component='h1' variant='h6' color='inherit' noWrap sx={{ flexGrow: 1 }}>
						{pageTitle}
					</Typography>
					<IconButton
						color='inherit'
						onClick={toggleTheme}
						title={theme === THEME_DARK ? TOGGLE_THEME_LIGHT : TOGGLE_THEME_DARK}
					>
						{theme === THEME_DARK ? <LightModeIcon /> : <DarkModeIcon />}
					</IconButton>
					<IconButton color='inherit'>
						<Badge badgeContent={0} color='secondary'>
							<NotificationsIcon />
						</Badge>
					</IconButton>
					{
						<div>
							{userDetails?.email}
							<IconButton
								size='large'
								aria-label='account of current user'
								aria-controls='menu-appbar'
								aria-haspopup='true'
								onClick={handleMenu}
								color='inherit'
							>
								<AccountCircle />
							</IconButton>
							<Menu
								id='menu-appbar'
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem onClick={() => navigate('/profile')}>Profile</MenuItem>
								<MenuItem onClick={logout}>Déconnexion</MenuItem>
							</Menu>
						</div>
					}
				</Toolbar>
			</AppBar>
			<Drawer variant='permanent' open={open}>
				<Toolbar
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						px: [1],
					}}
				>
					<IconButton onClick={toggleDrawer}>
						<ChevronLeftIcon />
					</IconButton>
				</Toolbar>
				<Sidebar />
			</Drawer>
			<Box
				component='main'
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
					flexGrow: 1,
					height: '100vh',
					overflow: 'auto',
				}}
			>
				<Toolbar />
				{container ? (
					<Container maxWidth='lg' sx={{ mt: 4, mb: 4, pr: 4, pl: 4 }}>
						{children}
						<Copyright sx={{ pt: 4 }} />
					</Container>
				) : (
					children
				)}
			</Box>
		</Box>
	);
}
