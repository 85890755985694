import { API_ERROR_OWNER_ONLY, APP_ERROR_OWNER_ONLY } from '../constants';

export const getGPSError = (error: any) => {
	switch (error.code) {
		case 1:
			return 'User denied the request for Geolocation.';
		case 2:
			return 'Location information is unavailable.';

		case 3:
			return 'The request to get user location timed out.';
		default:
			return 'An unknown error occurred.';
	}
};

export const getDatePickerError = (error: string | null) => {
	switch (error) {
		case 'maxDate':
		case 'minDate': {
			return 'Please select a date in the first quarter of 2022';
		}

		case 'invalidDate': {
			return 'Your date is not valid';
		}

		default: {
			return '';
		}
	}
};

export const VOID_FN = (_a: any, ..._args: any[]) => {};

export function isSIRET(numbr: string): boolean {
	// 12002701600357
	const num = numbr && numbr.replace(/\s/g, '');
	if (!Boolean(num) || num.length !== 14) return false; // check size
	// reverse and convert into integer
	const siret = Array.from(num)
		.reverse()
		.map((n) => parseInt(n));
	let sum = 0;
	for (let i = 0; i < siret.length; i++) {
		const temp = (i + 1) % 2 === 0 ? `${siret[i] * 2}` : `${siret[i] * 1}`;
		sum +=
			temp.length === 2
				? Array.from(temp).reduce((total, d) => {
						return total + parseInt(d);
				  }, 0)
				: parseInt(temp); // if two digits, sum both
	}

	return sum % 10 !== 0 ? false : true;
}

export const setSiretMask = (text: string) => {
	/* */
	if (text.length <= 3) return text.replace(/(\d{3})/, '$1');

	if (text.length > 3 && text.length <= 6) {
		return text.replace(/(\d{3})(\d*)/, '$1 $2');
	}

	if (text.length > 6 && text.length <= 9) {
		return text.replace(/(\d{3})(\d{3})(\d*)/, '$1 $2 $3');
	}

	return text.replace(/(\d{3})(\d{3})(\d{3})(\d*)/, '$1 $2 $3 $4');
};

export const dateToServerFormat = (frDate: string) =>
	frDate.replace(/(\d{2})\/(\d{2})\/(\d{4}) (\d*:\d*)/g, '$3-$2-$1T$4');

export const mapErrorToApiMessage = (message: string) => {
	let anwser = '';
	switch (message) {
		case API_ERROR_OWNER_ONLY:
			anwser = APP_ERROR_OWNER_ONLY;
			break;
	}
	return anwser;
};

export const slugify = (text: string) => {
	return String(text)
		.normalize('NFKD') // split accented characters into their base characters and diacritical marks
		.replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
		.trim() // trim leading or trailing whitespace
		.toLowerCase() // convert to lowercase
		.replace(/[^a-z0-9 -]/g, ' ') // remove non-alphanumeric characters
		.replace(/\s+/g, '-') // replace spaces with hyphens
		.replace(/-+/g, '-'); // remove consecutive hyphens;
};

export const formatCurrency = (
	amount: number,
	minimumFractionDigits: number = 2,
	currency: boolean = false
) =>
	currency
		? new Intl.NumberFormat('fr-FR', {
				style: 'currency',
				currency: 'CFA',
				minimumFractionDigits,
		  }).format(amount)
		: new Intl.NumberFormat('fr-FR', {
				minimumFractionDigits,
		  }).format(amount);

export const formatDate = (date: string, time = false) =>
	time
		? `${new Date(date).toLocaleDateString('fr')} à ${new Date(date).toLocaleTimeString('fr')}`
		: `${new Date(date).toLocaleDateString('fr')}`;
