import { PosItem } from '../../../types/PosItem';
import { PosService } from '../../../services/Pos';

export class PosController {
	static createDto(adDto: PosItem) {
		return adDto;
	}
	static updateDto(adDto: PosItem) {
		return adDto;
	}

	static async create(data: PosItem) {
		return await PosService.create(data);
	}

	static async update(data: PosItem) {
		return await PosService.update(data);
	}

	static async delete(id: string) {
		return await PosService.delete(id);
	}
}
