import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { BalanceService } from '../../../../services/Balance';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_EDIT_ERROR,
	LABEL_FEMALE_CREATION_ERROR,
	LABEL_FEMALE_CREATION_SUCCESS,
	LABEL_FEMALE_EDIT_SUCCESS,
} from '../../../../constants';
import { showAlertBox } from '../../../../store/alertBoxSlice';
import {
	Button,
	Grid,
	LinearProgress,
	TextField,
	Box,
	Typography,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';
import { DateTimePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BalanceItem } from '../../../../types/BalanceItem';
import { updatePageTitle } from '../../../../store/appDataSlice';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../../store';
import { loadBalanceDetails } from '../../../../store/balanceDataSlice';
import { LayoutBoard } from '../../../layouts/LayoutBoard';
import { AxiosError } from 'axios';
import { ServerError } from '../../../../types';
import { PosService } from '../../../../services/Pos';
import { loadPosDetails } from '../../../../store/posDataSlice';
import dayjs from 'dayjs';
import { getDatePickerError } from '../../../../utils';

export function BalanceForm() {
	//states
	const [isLoading, setIsLoading] = useState(false);
	const [income, setIncome] = useState(true);
	const [saleDateValue, setSaleDateValue] = useState(dayjs());

	const params = useParams();
	//hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const posDetails = useSelector((state: RootState) => state.posData.value);

	const {
		reset,
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();
	// methods
	const onSubmit = async (data: any) => {
		setIsLoading(true);
		setValue('income', income);
		console.log('saleDate ', data.saleDate);
		if (params?.id) {
			updateBalance({ ...data, id: params.id, saleDate: saleDateValue.toISOString() });
		} else {
			createBalance({ ...data, income });
		}
		setIsLoading(false);
		//
	};
	const handleIncomeChange = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		incomeValue: boolean
	) => {
		setIncome(incomeValue);
	};

	const handleSaleDateChange = (newValue: any) => {
		setSaleDateValue(newValue);
	};

	const [error, setError] = useState<DateValidationError | null>(null);

	const errorMessage = useMemo(() => {
		return getDatePickerError(error);
	}, [error]);

	const createBalance = async (data: any) => {
		console.log(data);
		try {
			const resp = await BalanceService.create(params.pos as string, data as BalanceItem);
			if (resp?.status && resp?.status === 201) {
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_SUCCESS,
						message: LABEL_FEMALE_CREATION_SUCCESS.replace('###', 'investissement'),
						open: true,
					})
				);
				resetForm();
				navigate(`/pos/${params.pos}/balances`);
			}
		} catch (error) {
			console.log(error);
			const serverError = (error as AxiosError).response?.data as ServerError;
			const message = serverError?.message
				? serverError.message
				: LABEL_FEMALE_CREATION_ERROR.replace('###', 'investissement');
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message,
					open: true,
				})
			);
		}
	};

	const updateBalance = async (data: any) => {
		const resp = await BalanceService.update(params.pos as string, data as BalanceItem);
		if (resp?.status === 200) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: LABEL_FEMALE_EDIT_SUCCESS.replace('###', 'recette'),
					open: true,
				})
			);
			resetForm();
			navigate(`/pos/${params.pos}/balances`);
		} else {
			console.log(resp);
			const message = resp?.data?.message ? resp?.data?.message : LABEL_EDIT_ERROR + 'recette';
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message,
					open: true,
				})
			);
		}
	};

	const loadPosData = () => {
		PosService.get(params.pos)
			.then((resp: any) => {
				dispatch(loadPosDetails(resp.data));
			})
			.catch((error) => {
				console.error(error);
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_ERROR,
						message: 'Erreur  lors du chargement des details la pos',
						open: true,
					})
				);
			});
	};

	const preFillForm = (balance: BalanceItem) => {
		const { amount, income, description, saleDate } = balance;
		setValue('amount', amount);
		setValue('income', income);
		setValue('description', description || '');
		setValue('saleDate', dayjs(saleDate).format('DD/MM/YYYY hh:mm'));
	};

	const resetForm = () => {
		reset();
	};

	useEffect(() => {
		const { id } = params;
		async function loadDetails(id: string) {
			const resp = await BalanceService.get(params.pos as string, id);
			if (resp?.status === 200) {
				dispatch(loadBalanceDetails(resp.data));
				preFillForm(resp.data as BalanceItem);
			}
		}
		loadPosData();
		if (Boolean(id)) {
			dispatch(updatePageTitle("Modification d'un investissement"));
			loadDetails(id as string);
		} else {
			dispatch(updatePageTitle("Création d'un investissement"));
		}
		// eslint-disable-next-line
	}, []);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
			<LayoutBoard container={true}>
				<Box component='form' noValidate onSubmit={handleSubmit(onSubmit)}>
					{isLoading && (
						<Box sx={{ width: '100%' }}>
							<LinearProgress />
						</Box>
					)}
					<Typography>Opération dans le point de vente {posDetails.name}</Typography>

					<Grid item xs={12} sx={{ p: 2, pl: 0 }}>
						<ToggleButtonGroup
							{...register('income')}
							value={income}
							exclusive
							onChange={handleIncomeChange}
							aria-label="Type d'opération"
						>
							<ToggleButton value={true} aria-label='entrée'>
								entrée
							</ToggleButton>
							<ToggleButton value={false} aria-label='sortie'>
								sortie
							</ToggleButton>
						</ToggleButtonGroup>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label='Montant'
								variant='outlined'
								type='number'
								{...register('amount', { required: true })}
								error={Boolean(errors.investor)}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label='Description'
								type='text'
								variant='outlined'
								{...register('description')}
								error={Boolean(errors.parts)}
							/>
						</Grid>
						<Grid item xs={6}>
							<DateTimePicker
								label="Date de l'opération"
								ampm={false}
								value={saleDateValue}
								{...register('saleDate', { required: true })}
								onChange={handleSaleDateChange}
								onError={(newError) => setError(newError as DateValidationError)}
								slotProps={{
									textField: {
										helperText: errorMessage,
									},
								}}
							/>
						</Grid>
					</Grid>
					<Grid container sx={{ mt: 5 }}>
						<Grid
							item
							xs={12}
							style={{ textAlign: 'right' }}
							sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
						>
							<Button
								type='button'
								onClick={() => navigate(`/pos/${params.pos}/balances`)}
								variant='outlined'
							>
								Retour
							</Button>{' '}
							<Button type='reset' onClick={resetForm} variant='outlined'>
								Annuler
							</Button>{' '}
							<Button type='submit' variant='contained'>
								Enregistrer
							</Button>
						</Grid>
					</Grid>
				</Box>
			</LayoutBoard>
		</LocalizationProvider>
	);
}
