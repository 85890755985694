import { RouteObject, createBrowserRouter } from 'react-router-dom';
import { Login } from '../components/pages/Auth/Login';
import { Register } from '../components/pages/Auth/Register';
import { Board } from '../components/pages/Board/Board';
import { Profile } from '../components/pages/Profile/Profile';
import { Pos } from '../components/pages/Pos/Pos';
import { PosForm } from '../components/pages/Pos/PosForm';
import { Balances } from '../components/pages/Pos/Balances/Balances';
import { BalanceForm } from '../components/pages/Pos/Balances/BalanceForm';
import { ResetPassword } from '../components/pages/Auth/ResetPassword';
import { RequestResetPassword } from '../components/pages/Auth/RequestResetPassword';
import { Auth } from '../services/Auth';
import { PosDetail } from '../components/pages/Pos/PosDetail';
import { MyBalances } from '../components/pages/Balances/MyBalances';

import HomeIcon from '@mui/icons-material/Home';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { RouteItem } from '../types/route';
import { Activation } from '../components/pages/Auth/Activation';
import { About } from '../components/pages/About/About';
import InfoIcon from '@mui/icons-material/Info';
import { Conditions } from '../components/pages/About/Conditions';
import { ADMIN, SELLER } from '../constants';
import { MyPos } from '../components/pages/Pos/MyPos';
import { isConnected } from '../middlewares';
import ErrorPage from '../components/pages/ErrorPage';

const user = Auth.getUser();
export const ROUTES: RouteItem[] = [
	{
		id: 1,
		path: '/',
		element: user ? <Board /> : <Login />,
		public: true,
		showMenu: false,
		icon: <HomeIcon />,
		label: 'Accueil',
		errorElement: <ErrorPage />,
	},
	{
		id: 2,
		path: '/board',
		element: <Board />,
		roles: [ADMIN, SELLER],
		load: isConnected,
	},
	{
		id: 3,
		path: '/login',
		element: <Login />,
		public: true,
	},
	{
		id: 4,
		path: '/register',
		element: <Register />,
		public: true,
	},
	{
		// activate user account
		id: 5,
		path: '/auth/activation/:activationToken',
		element: <Activation />,
		public: true,
	},
	{
		id: 6,
		path: '/auth/reset-password',
		element: <RequestResetPassword />,
		public: true,
	},
	{
		id: 7,
		path: '/auth/reset-password/:resetToken',
		element: <ResetPassword />,
		public: true,
	},
	{
		id: 8,
		path: '/profile',
		element: <Profile />,
		roles: [ADMIN, SELLER],
		load: isConnected,
	},
	{
		id: 9,
		path: '/pos',
		element: <Pos />,
		roles: [ADMIN],
		showMenu: true,
		icon: <AddBusinessIcon />,
		label: 'Point de ventes',
		load: isConnected,
	},
	{
		id: 9,
		path: '/my/pos',
		element: <MyPos />,
		roles: [SELLER],
		showMenu: true,
		icon: <AddBusinessIcon />,
		label: 'Point de ventes',
		load: isConnected,
	},
	{
		id: 10,
		path: '/pos/new',
		element: <PosForm />,
		roles: [SELLER],
		load: isConnected,
	},
	{
		id: 11,
		path: '/pos/:id',
		element: <PosForm />,
		roles: [SELLER],
		load: isConnected,
	},
	{
		id: 12,
		path: '/pos/:pos/balances',
		element: <Balances />,
		roles: [SELLER],
		load: isConnected,
	},
	{
		id: 13,
		path: '/pos/:pos/balances/new',
		element: <BalanceForm />,
		roles: [SELLER],
		load: isConnected,
	},
	{
		id: 14,
		path: '/pos/:pos/balances/:id',
		element: <BalanceForm />,
		roles: [SELLER],
		load: isConnected,
	},
	{
		id: 15,
		path: '/pos/detail/:id',
		element: <PosDetail />,
		roles: [SELLER],
		load: isConnected,
	},

	{
		id: 18,
		path: '/my/balances',
		element: <MyBalances />,
		roles: [SELLER],
		showMenu: true,
		icon: <CurrencyExchangeIcon />,
		label: 'Recettes',
		load: isConnected,
	},
	/**/
	{
		id: 20,
		path: '/about',
		element: <About />,
		roles: [ADMIN, SELLER],
		showMenu: true,
		icon: <InfoIcon />,
		label: 'A propos',
		load: isConnected,
	},
	{
		id: 21,
		path: '/cgu',
		element: <Conditions />,
		public: true,
	},
];

const router = createBrowserRouter(
	(user
		? ROUTES.filter(
				(routeItem) =>
					routeItem.public ||
					(routeItem.roles &&
						routeItem.roles.length &&
						routeItem.roles.includes(user.role as number))
		  )
		: ROUTES.filter((routeItem) => !!routeItem.public)) as RouteObject[]
);

export default router;
