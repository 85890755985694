import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, LinearProgress, Link, TextField, Typography } from '@mui/material';
import { Copyright } from '../../Copyright';
import { useState } from 'react';
import { Auth } from '../../../services/Auth';
import { User } from '../../../types/user';
import { useDispatch, useSelector } from 'react-redux';
import { showAlertBox } from '../../../store/alertBoxSlice';
import { DEFAULT_ALERT_ERROR, DEFAULT_ALERT_SUCCESS } from '../../../constants';
import { RootState } from '../../../store';
import Alert from '@mui/material/Alert';
import { Logo } from '../../common/Logo/Index';

export function RequestResetPassword() {
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset: resetForm,
	} = useForm();

	const [isLoading, setIsLoading] = useState(false);
	const dialog = useSelector((state: RootState) => state.alertBox.value);
	const { message, open: isOpen, severity } = dialog;

	const onSubmit = async (data: any) => {
		setIsLoading(true);
		const request = await Auth.requestPasswordReset(data as User);
		if (request) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: 'Demande de réinitialisation de mot de passe envoyée par email',
					open: true,
				})
			);
			resetForm();
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: 'Demande de réinitialisation de mot de passe echouée',
					open: true,
				})
			);
		}

		setIsLoading(false);
	};
	return (
		<Grid container component='main' sx={{ height: '100vh' }}>
			<Grid item sm={8} md={6}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Logo />
					<Typography component='h1' variant='h5'>
						Reinitialisation mot de passe
					</Typography>
					{isOpen && (
						<Box sx={{ width: '100%', marginTop: 2 }}>
							<Alert
								variant='filled'
								onClose={() => dispatch(showAlertBox({ ...DEFAULT_ALERT_ERROR, open: false }))}
								severity={severity}
							>
								{message}
							</Alert>
						</Box>
					)}
					<Box sx={{ width: '100%' }}> {isLoading && <LinearProgress />}</Box>

					<Box component='form' noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
						<Grid container>
							<Grid item xs={12}>
								<TextField
									autoComplete='email'
									{...register('email', { required: true })}
									error={Boolean(errors.email)}
									fullWidth
									id='email'
									label='E-mail'
									autoFocus
								/>
							</Grid>
						</Grid>
						<Button type='submit' fullWidth variant='contained' sx={{ mt: 1, mb: 2 }}>
							Reiniatialiser mot de passe
						</Button>
						<Grid container justifyContent='flex-end'>
							<Grid item>
								<Link href='/login' variant='body2'>
									Vous avez déjà un compte ? Connectez-vous
								</Link>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Copyright sx={{ mt: 5 }} />
			</Grid>
			<Grid
				item
				sm={12}
				md={6}
				sx={{
					backgroundImage: 'url(/bg2.jpg)',
					backgroundRepeat: 'no-repeat',
					backgroundColor: (t) =>
						t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			></Grid>
		</Grid>
	);
}
