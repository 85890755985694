import { Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { VOID_FN, formatDate } from '../../../utils';

export function PosColumns({
	handleItemEditClick = VOID_FN,
	handleItemRemoveClick = VOID_FN,
	handleItemRecettesClick = VOID_FN,
	handleItemDuplicationClick = VOID_FN,
}) {
	return [
		{
			field: 'name',
			headerName: 'Nom',
			width: 300,
			editable: false,
		},

		{
			field: 'startDate',
			headerName: 'debut',
			width: 150,
			editable: false,
			renderCell: (params: GridRenderCellParams) => formatDate(params.row.startDate),
		},
		{
			field: 'createdAt',
			headerName: 'date',
			width: 160,
			editable: false,
			renderCell: (params: GridRenderCellParams) => formatDate(params.row.createdAt, true),
		},
		{
			field: '',
			headerName: 'action',
			editable: false,
			width: 500,
			renderCell: (params: GridRenderCellParams) => (
				<>
					<Button variant='outlined' onClick={(e) => handleItemRecettesClick(e, params.row)}>
						Recettes
					</Button>
					<Button
						sx={{ marginLeft: '10px' }}
						variant='outlined'
						onClick={(e) => handleItemEditClick(e, params.row.id)}
					>
						Modifier
					</Button>
					<Button
						sx={{ marginLeft: '10px' }}
						variant='outlined'
						onClick={(e) => handleItemDuplicationClick(e, params.row)}
					>
						Dupliquer
					</Button>
					<Button
						sx={{ marginLeft: '10px' }}
						variant='outlined'
						onClick={(e) => handleItemRemoveClick(e, params.row)}
					>
						Supprimer
					</Button>
				</>
			),
		},
	] as GridColDef[];
}
