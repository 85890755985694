import { Button } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { VOID_FN, formatCurrency, formatDate } from '../../../utils';

export function MyBalancesColumns({ handleItemRemoveClick = VOID_FN }) {
	return [
		{
			field: 'pos.name',
			headerName: 'Pos',
			width: 500,
			editable: false,
			renderCell: (params: GridRenderCellParams) => params.row.pos.name,
		},
		{
			field: 'parts',
			headerName: 'parts',
			width: 80,
			editable: false,
			renderCell: (params: GridRenderCellParams) => `${params.row.parts}/${params.row.totalParts}`,
		},
		{
			field: 'amount',
			headerName: 'Montant (FCFA)',
			width: 120,
			editable: false,
			renderCell: (params: GridRenderCellParams) => formatCurrency(params.row.amount, 0),
		},
		{
			field: 'startDate',
			headerName: 'debut',
			width: 150,
			editable: false,
			renderCell: (params: GridRenderCellParams) => formatDate(params.row.pos.startDate),
		},
		{
			field: 'enDate',
			headerName: 'fin',
			width: 150,
			editable: false,
			renderCell: (params: GridRenderCellParams) => formatDate(params.row.pos.endDate),
		},
		{
			field: 'createdAt',
			headerName: 'date',
			width: 160,
			editable: false,
			renderCell: (params: GridRenderCellParams) => formatDate(params.row.createdAt, true),
		},
		{
			field: '',
			headerName: 'action',
			editable: false,
			width: 200,
			renderCell: (params: GridRenderCellParams) => (
				<>
					<Button
						sx={{ marginLeft: '10px' }}
						variant='outlined'
						onClick={(e) => handleItemRemoveClick(e, params.row)}
					>
						Supprimer
					</Button>
				</>
			),
		},
	] as GridColDef[];
}
