import { useDispatch } from 'react-redux';
import { Auth } from '../../../services/Auth';
import { updatePageTitle } from '../../../store/appDataSlice';
import { AdminBoard } from './AdminBoard';
import { SellerBoard } from './SellerBoard';
import { SELLER } from '../../../constants';

export function Board() {
	const dispatch = useDispatch();
	dispatch(updatePageTitle('Tableau de board'));
	return Auth.getUser()?.role === SELLER ? <SellerBoard /> : <AdminBoard />;
}
