import axios from 'axios';
import { APP_API_URL } from '../constants';
import { Auth } from './Auth';

export class ChartService {
	static async chart1To3(num: number) {
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'charts/chart-' + num,
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: `Bearer ${Auth.getToken()}`,
			},
		});
	}
	static async chart4(pos: string) {
		// pie chart balances by pos, availables / taken
		return await axios({
			method: 'GET',
			url: APP_API_URL + 'charts/chart-4/' + pos,
			headers: {
				'Access-Control-Allow-Origin': '*',
				Authorization: `Bearer ${Auth.getToken()}`,
			},
		});
	}
}
