import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Auth } from '../services/Auth';
export interface AppData {
	title: string;
	path: string;
	theme: string;
}
const initialState = {
	title: 'Dashboard',
	path: '',
	theme: 'light',
};

export const appDataSlice = createSlice({
	name: 'appData',
	initialState,
	reducers: {
		updatePageTitle: (state, action: PayloadAction<string>) => {
			state.title = action.payload;
		},
		updateTheme: (state, action: PayloadAction<string>) => {
			state.theme = action.payload;
		},
		userLogout: () => {
			Auth.logout();
		},
		userLoggedIn: (state, action: PayloadAction<string>) => {
			state.theme = action.payload;
		},
	},
});

export const { updatePageTitle, updateTheme, userLogout, userLoggedIn } = appDataSlice.actions;

export default appDataSlice.reducer;
