import {
	Box,
	Button,
	Card,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	LinearProgress,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { LayoutBoard } from '../../layouts/LayoutBoard';
import { Auth } from '../../../services/Auth';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	LABEL_REGISTRATION_CGU,
	LABEL_REGISTRATION_CGU_ERROR,
	DEFAULT_ALERT_ERROR,
	LABEL_EDIT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_EDIT_SUCCESS,
	ROLES,
} from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { updatePageTitle } from '../../../store/appDataSlice';
import { showAlertBox } from '../../../store/alertBoxSlice';
import { ProfileUser } from '../../../types/user';
import { RootState } from '../../../store';
import { loadProfileDetails } from '../../../store/profileDataSlice';
import { AxiosError } from 'axios';
import { ServerError } from '../../../types';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export function Profile() {
	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		getFieldState,
	} = useForm({ mode: 'onChange' });
	const dispatch = useDispatch();
	dispatch(updatePageTitle('Modification profile'));

	const profile = useSelector((state: RootState) => state.profileData.value);

	const onSubmit = async (data: any) => {
		try {
			await Auth.updateProfile(data as ProfileUser);
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: 'Profile ' + LABEL_EDIT_SUCCESS,
					open: true,
				})
			);
		} catch (err) {
			console.error(err);
			const error = (err as AxiosError)?.response?.data as ServerError;
			const message = error?.message ? error?.message : LABEL_EDIT_ERROR + ' profile';
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message,
					open: true,
				})
			);
		}
	};

	const loadProfileData = async () => {
		setIsLoading(true);
		Auth.profile()
			.then((resp: any) => {
				setIsLoading(false);
				dispatch(loadProfileDetails(resp.data));
				const { firstname, lastname, email, phone } = resp.data as ProfileUser;
				setValue('firstname', firstname);
				setValue('lastname', lastname);
				setValue('email', email);
				setValue('phone', phone || null);
			})
			.catch((error) => {
				console.log(error);
				setIsLoading(false);
				dispatch(
					showAlertBox({
						...DEFAULT_ALERT_ERROR,
						message: 'Impossible de charger le profile depuis le serveur',
						open: true,
					})
				);
			});
	};
	// start : hide/show password
	const handleClickShowPassword = () => {
		if (showPassword) setShowPassword(false);
		else setShowPassword(true);
	};
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	// end : hide/show password

	useEffect(() => {
		loadProfileData();
		// eslint-disable-next-line
	}, []);

	return (
		<LayoutBoard>
			<Typography variant='h4' sx={{ p: 2, pb: 0 }} style={{ textAlign: 'center' }}>
				Profile
			</Typography>
			<Grid container sx={{ p: 2, pt: 0 }} spacing={2}>
				<Grid item xs={12} sm={5}>
					<Card sx={{ p: 3, mt: 2 }}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<div
									style={{
										textAlign: 'center',
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
									}}
								>
									<div
										style={{
											width: 250,
											height: 250,
											borderRadius: '50%',
											backgroundColor: '#BBDEFB',
											marginBottom: 12,
										}}
									></div>
									<div>
										<Typography variant='h6' component='h3'>
											{`${profile.firstname} ${profile.lastname}`}
										</Typography>
										<Typography variant='body1'>{profile.email}</Typography>
										<Typography variant='body2'>
											{ROLES.find(({ id }) => id === profile.role)?.name}
										</Typography>
									</div>
								</div>
							</Grid>
						</Grid>
					</Card>
				</Grid>
				<Grid item xs={12} sm={7}>
					<Card component='form' noValidate onSubmit={handleSubmit(onSubmit)} sx={{ p: 3, mt: 2 }}>
						<Box sx={{ width: '100%' }}> {isLoading && <LinearProgress />}</Box>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									autoComplete='given-name'
									{...register('firstname', { required: true })}
									error={Boolean(errors.firstname)}
									fullWidth
									id='firstname'
									label='Prénom'
									autoFocus
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									{...register('lastname', { required: true })}
									error={Boolean(errors.lastname)}
									fullWidth
									id='lastname'
									label='Nom'
									name='lastname'
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>

							<Grid item xs={12}>
								<TextField
									{...register('email', { required: true })}
									error={Boolean(errors.email)}
									fullWidth
									id='email'
									label='E-mail'
									name='email'
									type='email'
									autoComplete='email'
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									{...register('phone', { required: getFieldState('phone').isDirty })}
									error={Boolean(errors.phone)}
									fullWidth
									id='phone'
									label='Téléphone'
									name='phone'
									type='text'
									autoComplete='phone'
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									{...register('password', {
										required: getFieldState('password').isDirty,
									})}
									error={Boolean(errors.password)}
									fullWidth
									label='Mot de passe'
									type={showPassword ? 'text' : 'password'}
									id='password'
									autoComplete='password'
									InputLabelProps={{ shrink: true }}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='afficher / masquer mot de passe'
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge='end'
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									{...register('confirm_password', {
										required: getFieldState('password').isDirty,
										validate: {
											equalPassword: (value, formValues) => formValues.password === value,
										},
									})}
									error={Boolean(errors.confirm_password)}
									fullWidth
									name='confirm_password'
									label='Confirmation mot de passe'
									type={showPassword ? 'text' : 'password'}
									id='confirm_password'
									autoComplete='confirm-password'
									InputLabelProps={{ shrink: true }}
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='afficher / masquer mot de passe'
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge='end'
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>

							<Grid item xs={12}>
								<FormControl
									required
									error={Boolean(errors.accepted_conditions)}
									component='fieldset'
									sx={{ m: 2 }}
									variant='standard'
								>
									{Boolean(errors.accepted_conditions) && (
										<FormHelperText>{LABEL_REGISTRATION_CGU_ERROR}</FormHelperText>
									)}
									<FormControlLabel
										control={
											<Switch
												value='accepted'
												{...register('accepted_conditions', {
													required: true,
												})}
												color='primary'
											/>
										}
										label={LABEL_REGISTRATION_CGU}
									/>
								</FormControl>
							</Grid>
						</Grid>
						<Button type='submit' fullWidth variant='contained' sx={{ mt: 1, mb: 2 }}>
							Enregister
						</Button>
					</Card>
				</Grid>
			</Grid>
		</LayoutBoard>
	);
}
