import React from 'react';
import { Button, Container, CssBaseline, Typography } from '@mui/material';
import { Link, useRouteError } from 'react-router-dom';
import { green } from '@mui/material/colors';

const styles = {
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		textAlign: 'center',
	},
	title: {
		fontSize: '4rem',
		color: green[700], // Rose Material-UI
	},
	subTitle: {
		fontSize: '1.5rem',
		marginBottom: '1rem',
	},
	bodyText: {
		fontSize: '1rem',
		marginBottom: '2rem',
	},
	button: {
		marginTop: '1.5rem',
	},
};

const ErrorPage = () => {
	const error = useRouteError();
	console.error(error);
	return (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<div style={styles.root}>
				<Typography variant='h2' style={styles.title}>
					{error.status}
				</Typography>
				<Typography variant='h5' style={styles.subTitle}>
					{error.statusText}
				</Typography>
				<Typography variant='body1' style={styles.bodyText}>
					La page que vous recherchez pourrait être dans une autre forteresse.
				</Typography>
				<Button variant='contained' style={styles.button} component={Link} to='/'>
					Retour à l'accueil
				</Button>
			</div>
		</Container>
	);
};

export default ErrorPage;
