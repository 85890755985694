import React from 'react';
import { useForm } from 'react-hook-form';
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	LinearProgress,
	Paper,
	Switch,
	TextField,
	Typography,
} from '@mui/material';
import { Copyright } from '../../Copyright';
import { useState } from 'react';
import { Auth } from '../../../services/Auth';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { showAlertBox } from '../../../store/alertBoxSlice';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_REGISTRATION_CGU_ERROR,
	LABEL_RESET_PASSWORD_CGU,
} from '../../../constants';
import { RootState } from '../../../store';
import Alert from '@mui/material/Alert';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Logo } from '../../common/Logo/Index';

export function ResetPassword() {
	const dispatch = useDispatch();
	const params = useParams();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset: resetForm,
	} = useForm();

	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const dialog = useSelector((state: RootState) => state.alertBox.value);
	const { message, open: isOpen, severity } = dialog;
	// start : hide/show password
	const handleClickShowPassword = () => {
		if (showPassword) setShowPassword(false);
		else setShowPassword(true);
	};
	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};
	// end : hide/show password

	const onSubmit = async (data: any) => {
		setIsLoading(true);
		const { password } = data;
		const reset = await Auth.resetPassword({ password } as any, params.resetToken as string);
		if (reset) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: 'la modification de votre mot de passe a été effectuée avec succès',
					open: true,
				})
			);
			resetForm();
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: 'la modification de votre mot de passe a échouée',
					open: true,
				})
			);
		}

		setIsLoading(false);
	};
	return (
		<Grid container component='main' sx={{ height: '100vh' }}>
			<Grid item component={Paper} elevation={6} sm={8} md={6}>
				<Box
					sx={{
						my: 2,
						mx: 1,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Logo />
					<Typography component='h1' variant='h5' sx={{ textAlign: 'center' }}>
						Reinitialisation mot de passe <br /> Nouveau mot de passe
					</Typography>
					{isOpen && (
						<Box sx={{ width: '100%', marginTop: 2 }}>
							<Alert
								variant='filled'
								onClose={() => dispatch(showAlertBox({ ...DEFAULT_ALERT_ERROR, open: false }))}
								severity={severity}
							>
								{message}
							</Alert>
						</Box>
					)}
					<Box sx={{ width: '100%' }}> {isLoading && <LinearProgress />}</Box>

					<Box component='form' noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
						<Grid container sx={{ p: 3 }}>
							<Grid item xs={12} sx={{ pt: 4 }}>
								<TextField
									autoComplete='password'
									{...register('password', { required: true })}
									error={Boolean(errors.password)}
									fullWidth
									type={showPassword ? 'text' : 'password'}
									id='password'
									label='Mot de passe'
									autoFocus
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='afficher / masquer mot de passe'
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge='end'
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} sx={{ pt: 2 }}>
								<TextField
									autoComplete='confirm_password'
									{...register('confirm_password', {
										required: true,
										validate: {
											rule1: (value, formValues) => value === formValues.password,
										},
									})}
									error={Boolean(errors.confirm_password)}
									fullWidth
									type={showPassword ? 'text' : 'password'}
									id='confirm_password'
									label='Confirmer mot de passe'
									autoFocus
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton
													aria-label='afficher / masquer mot de passe'
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
													edge='end'
												>
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl
									required
									error={Boolean(errors.accepted_conditions)}
									component='fieldset'
									sx={{ m: 2 }}
									variant='standard'
								>
									{Boolean(errors.accepted_conditions) && (
										<FormHelperText>{LABEL_REGISTRATION_CGU_ERROR}</FormHelperText>
									)}
									<FormControlLabel
										control={
											<Switch
												value='accepted'
												{...register('accepted_conditions', {
													required: true,
												})}
												color='primary'
											/>
										}
										label={LABEL_RESET_PASSWORD_CGU}
									/>
								</FormControl>
							</Grid>
						</Grid>
						<Button type='submit' fullWidth variant='contained' sx={{ mt: 1, mb: 2 }}>
							Modifier mon mot de passe
						</Button>
					</Box>
				</Box>
				<Copyright sx={{ mt: 5 }} />
			</Grid>
			<Grid
				item
				sm={12}
				md={6}
				sx={{
					backgroundImage: 'url(/bg3.jpg)',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			></Grid>
		</Grid>
	);
}
