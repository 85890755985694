import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { PosService } from '../../../services/Pos';
import {
	DEFAULT_ALERT_ERROR,
	DEFAULT_ALERT_SUCCESS,
	LABEL_EDIT_ERROR,
	LABEL_FEMALE_CREATION_ERROR,
	LABEL_FEMALE_CREATION_SUCCESS,
	LABEL_FEMALE_EDIT_SUCCESS,
} from '../../../constants';
import { showAlertBox } from '../../../store/alertBoxSlice';
import { Button, Grid, LinearProgress, TextField, Box } from '@mui/material';
import { PosItem } from '../../../types/PosItem';
import { updatePageTitle } from '../../../store/appDataSlice';
import { useParams } from 'react-router-dom';
import { loadPosDetails } from '../../../store/posDataSlice';
import { LayoutBoard } from '../../layouts/LayoutBoard';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { DateTimePicker, DateValidationError, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getDatePickerError } from '../../../utils';

export function PosForm() {
	//states
	const [isLoading, setIsLoading] = useState(false);
	const params = useParams();

	const [minDate] = useState(dayjs());
	const [startDateValue, setStartDateValue] = useState(dayjs());
	//hooks
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [error, setError] = useState<DateValidationError | null>(null);

	const errorMessage = useMemo(() => {
		return getDatePickerError(error);
	}, [error]);

	const {
		reset,
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm();
	// methods
	const onSubmit = async (data: any) => {
		setIsLoading(true);
		if (params?.id) {
			updatePos({ id: params.id, ...data });
		} else {
			createPos(data);
		}
		setIsLoading(false);
		//
	};

	const createPos = async (data: any) => {
		const resp = await PosService.create(data as PosItem);
		if (resp?.status === 201) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: 'point de ventes ' + LABEL_FEMALE_CREATION_SUCCESS,
					open: true,
				})
			);
			resetForm();
			navigate('/my/pos');
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: 'point de ventes ' + LABEL_FEMALE_CREATION_ERROR,
					open: true,
				})
			);
		}
	};

	const updatePos = async (data: any) => {
		const resp = await PosService.update(data as PosItem);
		if (resp?.status === 200) {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_SUCCESS,
					message: 'point de ventes ' + LABEL_FEMALE_EDIT_SUCCESS,
					open: true,
				})
			);
			resetForm();
			navigate('/my/pos');
		} else {
			dispatch(
				showAlertBox({
					...DEFAULT_ALERT_ERROR,
					message: LABEL_EDIT_ERROR + 'point de ventes',
					open: true,
				})
			);
		}
	};

	const preFillForm = (pos: PosItem) => {
		const { name, startDate, description } = pos;
		setValue('name', name);
		setValue('description', description);
		setValue('startDate', startDate);
	};

	const resetForm = () => {
		reset();
	};

	const handleStartDateChange = (newValue: any) => {
		setStartDateValue(newValue);
	};

	useEffect(() => {
		const { id } = params;

		async function loadDetails(id: any) {
			const resp = await PosService.get(id);
			if (resp?.status === 200) {
				dispatch(loadPosDetails(resp.data));
				preFillForm(resp.data as PosItem);
			}
		}
		if (Boolean(id)) {
			dispatch(updatePageTitle("Modification d'un point de vente"));
			loadDetails(id);
		} else {
			dispatch(updatePageTitle("Création d'un point de vente"));
		}
		// eslint-disable-next-line
	}, []);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
			<LayoutBoard container={true}>
				<Box component='form' noValidate onSubmit={handleSubmit(onSubmit)}>
					{isLoading && (
						<Box sx={{ width: '100%' }}>
							<LinearProgress />
						</Box>
					)}
					Point de vente (activité)
					<Grid container style={{ marginBottom: 50 }}>
						<Grid
							item
							xs={12}
							style={{
								textAlign: 'right',
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								gap: 10,
							}}
						>
							<Button type='button' onClick={() => navigate('/pos')} variant='outlined'>
								Retour
							</Button>
							<Button type='reset' onClick={resetForm} variant='outlined'>
								Annuler
							</Button>
							<Button type='submit' variant='contained'>
								Enregistrer
							</Button>
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								fullWidth
								label='Nom'
								variant='outlined'
								{...register('name', { required: true })}
								error={Boolean(errors.name)}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								InputLabelProps={{ shrink: true }}
								multiline
								fullWidth
								label='Description'
								variant='outlined'
								rows={4}
								maxRows={4}
								{...register('description')}
							/>
						</Grid>

						<Grid item xs={6}>
							<DateTimePicker
								label='Date début'
								minDateTime={minDate}
								ampm={false}
								value={startDateValue}
								{...register('startDate', { required: true })}
								onChange={handleStartDateChange}
								onError={(newError) => setError(newError as DateValidationError)}
								slotProps={{
									textField: {
										helperText: errorMessage,
									},
								}}
							/>
						</Grid>
					</Grid>
				</Box>
			</LayoutBoard>
		</LocalizationProvider>
	);
}
